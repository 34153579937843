/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/04/2021.
 */
import * as queryString from 'query-string'
import { win } from './browserMock'
import { UTM_KEY } from '../constant'

console.log(' utm > UTM_KEY = ', UTM_KEY)
export const setUtm = (query) => {
  if (!query) {
    return null
  }

  const newUtm = queryString.parse(query)

  const oldUtm = getUtm()
  const updatedUtm = { visits: 0 }

  //
  // handle legacy utm strings - convert them to arrays, to track multiple visits from campaigns
  //
  validateUtm('utm_campaign', oldUtm, newUtm, updatedUtm)
  validateUtm('utm_content', oldUtm, newUtm, updatedUtm)
  validateUtm('utm_medium', oldUtm, newUtm, updatedUtm)
  validateUtm('utm_source', oldUtm, newUtm, updatedUtm)
  validateUtm('utm_study', oldUtm, newUtm, updatedUtm)

  // detect visits
  const hasVisits = oldUtm.visits ? oldUtm.visits : 0
  const visitIsNumber = Number(hasVisits) === 'NaN' ? 0 : Number(hasVisits)
  updatedUtm.visits = visitIsNumber + 1

  const updatedUtmStr = JSON.stringify(updatedUtm, null, 2)
  win.localStorage.setItem(UTM_KEY, updatedUtmStr)
}

const validateUtm = (key, oldUtm, newUtm, updatedUtm) => {
  const valueArr = oldUtm[key] ? oldUtm[key] : []
  const newValue = newUtm[key] ? newUtm[key] : ''
  const newValueArr = valueArr instanceof Array === true ? valueArr : [oldUtm[key]]

  updatedUtm[key] = addIfNotDuplicate(newValue, newValueArr)
}

const addIfNotDuplicate = (key, arr) => {
  if (arr.indexOf(key) === -1) {
    console.log(' utm > add utm value ')
    return arr.concat([key])
  }

  return arr
}

export const getUtm = () => {
  const strObj = win.localStorage.getItem(UTM_KEY)

  if (strObj) {
    return parseJson(strObj)
  } else {
    return {}
  }
}

const parseJson = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return {}
  }
}
