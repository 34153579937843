//import { authStore, getRefreshToken, getToken } from '../store/auth/authStore'
//import { DEBUG } from "../constants"
//import { Auth } from 'aws-amplify'

const DEBUG = false
export const getReq = async (p: any): Promise<any> => {
  const token = ''

  return fetch(p, {
    method: 'GET',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      //Authorization: 'Bearer ' + token,
    },
  })
    .then((res) => {
      const contentType = res.headers.get('Content-Type')

      // mapbox returns application/vnd.geo+json
      //'application/json'
      const isJson = contentType && contentType.indexOf('json') > -1

      if (DEBUG) {
        console.log(' API > DEBUG res.status = ', res.status, res.statusText)
      }

      if (res.status === 404) {
        console.log(' API > res = ', res)
        const url = res.url || ''
        return Promise.reject(getErrorResponse(res, 404, 'Could not find endpoint ' + url))
      }

      if (res.status === 401) {
        console.log(' API GET > 401 401 401 401401 = ')
        return
      }

      if (!isJson) {
        return res.text()
      }

      return res.json()
    })
    .then((res) => {
      if (res.error || res.result === false) {
        return Promise.reject(res)
      }

      return res
    })

    .catch((err) => {
      return Promise.reject(err)
    })
}

export const post = async (p: string, data: any) => {
  //const token = getToken()

  const token = '' //(await Auth.currentSession()).getIdToken().getJwtToken()

  return fetch(p, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (DEBUG) {
        console.log(' API > res.status = ', res.status, p)
      }

      if (res.status === 401) {
        console.log(' API POST > 401 401 401 401401 = ')
        return Promise.reject(res)
        //return updateAccessToken(p, 'POST', data)
      }

      return res.json()
    })
    .then((res) => {
      if (res.result === false) {
        return Promise.reject(res)
      }
      return res
    })

    .catch((err) => {
      return Promise.reject(err)
    })
}

function getErrorResponse(err, status: number, msg) {
  return {
    result: false,
    status: status,
    err: err,
    message: msg,
  }
}
