/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 20/02/2021.
 */

import { DEBUG } from '../constant'
import { win } from './browserMock'

export const sendToDataLayer = (obj) => {
  if (DEBUG) {
    console.log(' DataLayer > is not active ', obj)
    return
  }

  win.dataLayer.push(obj)
}
