/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/11/2022.
 */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FadeUp from '../animation/FadeUp'

type Props = {}

export const WhoWeAreLookingFor = ({}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid section-margin position-relative ">
      <div className="container  position-relative pt-3 pb-4">
        <div className="row ">
          <FadeUp className="col-12 mt-4 mb-md-5">
            <h3 className="text-center mb-3">{t('questionBold3')}</h3>
            <h5 className="text-center mb-4 mb-md-2">{t('imageTextSubHeadline1')}</h5>
          </FadeUp>

          <FadeUp delay={0.4} className="col-12 col-md-4 noselect ">
            <div className="position-relative w-100 h-100 ">
              <div className="px-md-4 " style={{ top: 70, left: 40, zIndex: 10 }}>
                <h6 className="fw-bold text-black text-center my-4">{t('imageTextHeadline1')}</h6>
              </div>
            </div>
          </FadeUp>

          <FadeUp delay={0.4} className="col-12 col-md-4 noselect d-flex justify-content-center">
            <div className="position-relative w-100 h-100 ">
              <div className="px-md-4" style={{ top: 70, left: 40, zIndex: 10 }}>
                <h6 className="fw-bold text-black my-4 text-center">{t('imageTextHeadline2')}</h6>
              </div>
            </div>
          </FadeUp>

          <FadeUp delay={0.4} className="col-12 col-md-4 noselect d-flex justify-content-center">
            <div className="position-relative w-100 h-100 ">
              <div className="px-md-4" style={{ top: 50, left: 50, zIndex: 10 }}>
                <h6 className="fw-bold text-black  my-4 text-center">{t('imageTextHeadline3')}</h6>
              </div>
            </div>
          </FadeUp>
        </div>
      </div>
    </div>
  )
}
