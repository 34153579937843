import create, { State } from 'zustand'
import { BASE_URL, GATSBY_IP_LOCATION, GOOGLE_MAPS_API_KEY } from '../constant'
import { getReq, post } from '../service/API'

type UserLocation = {
  calling_code: string
  city: string
  continent_code: string
  continent_name: string
  country_code: string
  country_name: string
  emoji_flag: string
  emoji_unicode: string
  flag: string
  ip: string
  is_eu: boolean
  latitude: number
  longitude: number
  postal: string
  region: string
  region_code: string
}

const validCountries = ['us']

export interface DataStoreType extends State {
  userLocation: null | UserLocation
  getLocation: () => void
  validCountry: boolean
}

export const dataStore = create<DataStoreType>((set: any, get: any) => {
  return {
    userLocation: null,
    validCountry: false,

    getLocation: (testIp) => {
      //const APIKEY_IPDATA = '728fc569d71e604ea824f3b6adffffeaae0b1c1c0174628f4ee27e41'
      const APIKEY_IPDATA = GATSBY_IP_LOCATION

      /*const p =
        `https://api.ipdata.co/${testIp}?api-key=` +
        APIKEY_IPDATA +
        '&fields=ip,is_eu,city,region,region_code,country_name,country_code,continent_name,continent_code,latitude,longitude,postal,calling_code,flag,emoji_flag,emoji_unicode'*/

      //const pp = 'https://api.ipdata.co/8.8.8.8?api-key=' + APIKEY_IPDATA

      const pp =
        `https://api.ipdata.co/${testIp}?api-key=` + APIKEY_IPDATA + '&fields=ip,country_code,latitude,longitude'

      return getReq(pp)
        .then((result) => {
          console.log(' dataStore > result = ', result)

          const userCountry = result.country_code ? result.country_code.toLowerCase() : ''
          const isValidCountry = validCountries.indexOf(userCountry) > -1 ? true : false

          set({ userLocation: result, validCountry: isValidCountry })
          return result
        })

        .catch((error) => {
          console.log('error = ', error)
          return error
        })
    },

    /*queryZipcode: (input, country) => {
      const components = `country:${country}|postal_code:${input}`
      const p =
        `https://maps.google.com/maps/api/geocode/json?components=${components}&sensor=false&key=` + GOOGLE_MAPS_API_KEY

      return fetch(p, {
        method: 'GET',
        //body: JSON.stringify({}),
        headers: {
          //  mode: 'cors',
          Accept: 'application/json',
          // 'Content-Type': 'application/json',
          //Authorization: 'Bearer ' + token,
        },
      })
        .then((result) => {
          return result.json()
        })
        .then((result) => {
          return result
          console.log('result RESULT = ', result)
        })
        .catch((error) => {
          console.log('error = ', error)
        })
    },*/
  }
})
