/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17-08-2020.
 */
import React, { useRef } from 'react'
import gsap from 'gsap'
import { InView } from 'react-intersection-observer'

const FadeUp = ({ className = '', style = {}, delay = 0.2, children, name = '' }) => {
  const lastTime = useRef(0)

  const handleEnter = (inView, entry) => {
    const dir = entry.intersectionRect.top > 100 ? 'up' : 'down'

    //    console.log(' FadeUp > name = ', name)

    const ypos = dir === 'up' ? 10 : -10

    const threshold = 40
    const diffTime = entry.time - lastTime.current
    const newYpos = diffTime < threshold ? 0 : ypos

    if (diffTime < threshold) {
      return
    }

    lastTime.current = entry.time

    if (inView) {
      gsap.killTweensOf(entry.target)
      gsap.fromTo(
        entry.target,
        { y: newYpos, opacity: 0 },
        { duration: 0.6, y: 0, opacity: 1, delay: delay, ease: 'Expo.easeOut' }
      )
    } else {
      gsap.set(entry.target, { opacity: 0 })
    }
  }

  return (
    <InView threshold={0} className={className} style={{ ...style }} onChange={handleEnter}>
      {children}
    </InView>
  )
}

export default FadeUp
